import * as React from 'react'
import { Flex } from 'grid-styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'
import Hero from '../../components/Hero'
import Button from '../../components/Button'
import BackgroundSection from '../../components/BackgroundSection'
import MainLayout from '../../components/Layout'
import { AboutContact } from '..'
import {
  BeforeAfter,
  StyledTabs,
  StyledTabList,
  StyledTab,
  Content
} from '../../components/procedures'
import { TabPanel } from 'react-tabs'
import FAQs from '../../components/FAQs'
import faqs from '../faqs'

const content = {
  backgroundTitle: 'Other Psychology Services',
  subtitle: `Evidence based treatment and coping strategies for a range of clinical issues.`,
  title: 'Mental Health Services',
  otherOptions: [
    {
      text: 'Disability Specialist Services',
      to: '/services/positive-behaviour-support'
    },
    {
      text: 'Supervision',
      to: '/services/supervision'
    },
    {
      text: 'Agency Specialist Services',
      to: '/services/training-agency-specialist-services'
    }
  ]
}

export default ({ data }) => (
  <MainLayout header="dark">
    <Helmet>
      <title>
        Mental Health Services - Brisbane & Gold Coast Psychology - Upright Performance
      </title>
      <meta
        name="description"
        content="Rob provides evidence-based treatment for various issues you may be grappling with. He can provide you with a range of coping skills and strategies to assist you."
      />
    </Helmet>

    <Hero
      skew={true}
      bgImage={data.womanWaterfall.childImageSharp}
      height="600px"
      noForm={true}
    >
      <h1>{content.title}</h1>
      <p>{content.subtitle}</p>
      <Flex>
        <Link to="/contact">
          <Button color="#fff" small={true} outline={true}>
            Contact Rob Today
          </Button>
        </Link>
      </Flex>
    </Hero>

    <Content>
      <h2>Mental Health Services</h2>
      <span>
        Rob provides evidence-based treatment for various issues you may be
        grappling with. He can provide you with a range of coping skills and
        strategies to assist you.
      </span>

      <h4>Rob can help you with </h4>

      <ul>
        <li>Anxiety</li>
        <li>Depression</li>
        <li>Anger </li>
        <li>Stress management </li>
        <li>Change management</li>
        <li>Grief and loss</li>
        <li>Trauma</li>
        <li>Disability</li>
      </ul>

      <h4>Who Rob works with</h4>

      <p>
        Rob has over a decade’s experience working with older children,
        adolescents and adults from all backgrounds.
      </p>

      <h4>How Rob works</h4>

      <p>
        Rob uses Cognitive Behaviour Therapy, Acceptance and Commitment Therapy
        (ACT), and Solution-Focused Interventions to assist you in working
        though issues that you may de dealing with. Rob has found that
        partnering with clients to acquire new skills and helping to maximise
        strengths has been effective in helping navigate challenging times.
      </p>

      <p>
        Therapy is conducted in an open nonjudgmental environment and is driven
        by an individual’s priorities.
      </p>

      <Link to="/contact/">
        <Button inverted={true}>Contact Rob Today</Button>
      </Link>
    </Content>

    {/* <BackgroundSection
      image={data.otherBreast.childImageSharp}
      title={content.backgroundTitle}
      height="400px"
      darkness="0.4"
    >
      {content.otherOptions.map(({ to, text }) => (
        <Flex mr={2} mb={3} style={{ float: 'left' }}>
          <Link to={to}>
            <Button small={true} color="#fff" outline={true}>
              {text}
            </Button>
          </Link>
        </Flex>
      ))}
    </BackgroundSection> */}

    <AboutContact noBorder={true} data={data} />
  </MainLayout>
)
export const query = graphql`
  query MentalQuery {
    allFile(filter: { relativeDirectory: { eq: "breast-augmentation" } }) {
      edges {
        node {
          childImageSharp {
            resolutions(width: 500, height: 300, toFormat: JPG) {
              ...GatsbyImageSharpResolutions
            }
          }
        }
      }
    }
    womanWaterfall: file(relativePath: { eq: "mental-health-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    otherBreast: file(relativePath: { eq: "happy-woman.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    implant: file(relativePath: { eq: "implant-place.jpg" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    implantType: file(relativePath: { eq: "implant-type.jpg" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    implantType2: file(relativePath: { eq: "cut-smooth-implant.jpg" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    implantProfile: file(relativePath: { eq: "profile.jpg" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asaps: file(relativePath: { eq: "ASAPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asps: file(relativePath: { eq: "ASPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    drSellars: file(relativePath: { eq: "camping.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fracs: file(relativePath: { eq: "FRACS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
